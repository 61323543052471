export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Categories',
    to: '/categories',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Attributes',
    to: '/attributes',
    icon: 'cilTask',
  },
  {
    component: 'CNavItem',
    name: 'Items',
    to: '/items',
    icon: 'cilInbox',
  },
  {
    component: 'CNavItem',
    name: 'Reviews',
    to: '/reviews',
    icon: 'cilSpeech',
  },
  {
    component: 'CNavItem',
    name: 'Predictive messages',
    to: '/predictive-messages',
    icon: 'cilEnvelopeClosed',
  },
  {
    component: 'CNavItem',
    name: 'Item reports',
    to: '/item-reports',
    icon: 'cilWarning',
  },
  {
    component: 'CNavItem',
    name: 'Item colors',
    to: '/colors',
    icon: 'cilOptions',
  },
]
