//import axios from 'axios'
import TokenService from './token.service'
import api from './api'
class AuthService {
  prefix = 'api/auth/'
  async login(user) {
    return await api
      .post(this.prefix + '/login', {
        email: user.email,
        password: user.password,
      })
      // return axios
      //   .post(
      //     'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' +
      //       process.env.VUE_APP_GOOGLE_API_KEY,
      //     {
      //       email: user.email,
      //       password: user.password,
      //       returnSecureToken: true,
      //     },
      //   )
      .then(async (response) => {
        const result = response.data
        const user = result.user
        if (user.role !== 'admin') {
          throw Error('NOT_AUTHORIZED')
        }

        TokenService.setUser({
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          id: user.id,
          phone: user.phone,
          picture: user.picture,
          role: user.role,
          status: user.status,
          name: user.name,
        })
      })
  }

  logout() {
    TokenService.removeUser()
  }
}

export default new AuthService()
