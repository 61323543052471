class TokenService {
  getLocalRefreshToken() {
    // Refresh tokens expire only when one of the following occurs:
    // 1. The user is deleted.
    // 2. The user is disabled.
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.refreshToken
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.accessToken
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'))
    user.accessToken = token
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('user')
  }
}

export default new TokenService()
