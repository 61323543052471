<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatarUrl" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <CDropdownItem @click="logout" href="#">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/2.jpg'
import { useStore } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 42,
    }
  },
  data: () => {
    return {
      currentUser: {},
      avatarUrl: '',
    }
  },
  mounted() {
    const store = useStore()
    this.currentUser = store.state.auth.user
    this.avatarUrl = this.currentUser?.picture
      ? `https://${process.env.VUE_APP_MINIO_URL}/${process.env.VUE_APP_AWS_S3_PROFILE_PICTURES_BUCKET_NAME}/users/${this.currentUser.id}/${this.currentUser.picture}`
      : avatar
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
}
</script>
