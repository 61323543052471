import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/user',
        name: 'users',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/users',
        children: [
          {
            name: '',
            path: '/users',
            component: () => import('@/views/users/index'),
          },
          {
            name: 'create-user',
            path: '/users/create',
            component: () => import('@/views/users/create'),
          },
          {
            name: 'edit-user',
            path: '/users/:id/edit',
            component: () => import('@/views/users/edit'),
          },
          {
            name: 'show-user',
            path: '/users/:id/show',
            component: () => import('@/views/users/show'),
          },
        ],
      },
      {
        path: '/categories',
        name: 'categories',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/categories',
        children: [
          {
            name: '',
            path: '/categories',
            component: () => import('@/views/categories/index'),
          },
          {
            name: 'create-category',
            path: '/categories/create',
            component: () => import('@/views/categories/create'),
            props: (route) => ({ parent: route.query.parent }),
          },
          {
            name: 'edit-category',
            path: '/categories/:id/edit',
            component: () => import('@/views/categories/edit'),
            props: (route) => ({ parent: route.query.parent }),
          },
        ],
      },
      {
        path: '/attributes',
        name: 'attributes',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/attributes',
        children: [
          {
            name: '',
            path: '/attributes',
            component: () => import('@/views/attributes/index'),
          },
          {
            name: 'create-attribute',
            path: '/attributes/create',
            component: () => import('@/views/attributes/create'),
          },
          {
            name: 'edit-attribute',
            path: '/attributes/:id/edit',
            component: () => import('@/views/attributes/edit'),
          },
        ],
      },
      {
        path: '/items',
        name: 'items',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/items',
        children: [
          {
            name: '',
            path: '/items',
            component: () => import('@/views/items/index'),
          },
          {
            name: 'create-item',
            path: '/items/create',
            component: () => import('@/views/items/create'),
          },
          {
            name: 'show-item',
            path: '/items/:id/show',
            component: () => import('@/views/items/show'),
          },
          {
            name: 'edit-item',
            path: '/items/:id/edit',
            component: () => import('@/views/items/edit'),
          },
        ],
      },
      {
        path: '/reviews',
        name: 'reviews',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/reviews',
        children: [
          {
            name: '',
            path: '/reviews',
            component: () => import('@/views/reviews/index'),
          },
          {
            name: 'show-review',
            path: '/reviews/:id/show',
            component: () => import('@/views/reviews/show'),
          },
          {
            name: 'edit-review',
            path: '/reviews/:id/edit',
            component: () => import('@/views/reviews/edit'),
          },
        ],
      },
      {
        path: '/predictive-messages',
        name: 'predictive-messages',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/predictive-messages',
        children: [
          {
            name: '',
            path: '/predictive-messages',
            component: () => import('@/views/predictive-messages/index'),
          },
          {
            name: 'create-predictive-message',
            path: '/predictive-messages/create',
            component: () => import('@/views/predictive-messages/create'),
          },
          {
            name: 'edit-predictive-message',
            path: '/predictive-messages/:id/edit',
            component: () => import('@/views/predictive-messages/edit'),
          },
        ],
      },
      {
        path: '/item-reports',
        name: 'item-reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/item-reports',
        children: [
          {
            name: '',
            path: '/item-reports',
            component: () => import('@/views/item-reports/index'),
          },
        ],
      },
      {
        path: '/color',
        name: 'colors',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/colors',
        children: [
          {
            name: '',
            path: '/colors',
            component: () => import('@/views/colors/index'),
          },
          {
            name: 'create-color',
            path: '/colors/create',
            component: () => import('@/views/colors/create'),
          },
          {
            name: 'edit-color',
            path: '/colors/:id/edit',
            component: () => import('@/views/colors/edit'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/pages/password-reset.vue'),
  },
  {
    path: '/password-reset-confirm',
    name: 'password-reset-confirm',
    component: () => import('@/views/pages/password-reset-confirm.vue'),
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/email-verification.vue'),
  },
  {
    path: '/open-app',
    name: 'open-app',
    component: () => import('@/views/pages/download-btns.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/password-reset',
    '/password-reset-confirm',
    '/verify-email',
    '/open-app',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
