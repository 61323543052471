import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'accept-language': 'en',
  },
})

export default instance
