import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from './services/setupInterceptors'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import VueTelInput from 'vue-tel-input'
import Toaster from '@meforma/vue-toaster'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
setupInterceptors(store)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueTelInput)
app.use(Toaster, {
  position: 'top-right',
})
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    language: 'en',
    libraries: 'places',
  },
})

app.provide('icons', icons)

app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
