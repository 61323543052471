<template>
  <CFooter>
    <div>
      <a href="https://kayisoft.net/" class="link" target="_blank">Kayisoft</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
