<template>
  <router-view />
</template>
<script>
import EventBus from './common/EventBus'
export default {
  name: 'App',
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
  mounted() {
    EventBus.on('logout', () => {
      this.logout()
    })
  },
  beforeUnmount() {
    EventBus.remove('logout')
  },
}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
